<template>
  <div style="overflow-x: hidden">
    <headerer></headerer>
    <div class="div">
      <div class="imgshowtop">
        <img src="https://oss.001ke.com/website-m/wellperson/bg.png" alt="topimg" />
      </div>

      <div class="box">
        <div class="top">
          <div class="left">
            <img :src="topinfo.imgurl" alt="" />
          </div>

          <div class="right">
            <div class="info">
              <h2>{{ topinfo.name }}</h2>
              <p v-for="(item, index) in topinfo.des" :key="index">
                {{ item }}
              </p>
            </div>
          </div>
        </div>
        <div class="carousel">
          <div
            class="carouselbtn"
            style="padding-right: 10px"
            @click="carouselLeft"
          >
            《
          </div>
          <div class="carouselmain">
            <div v-for="(item, index) in topdemo" :key="index">
              <div
                :class="index === topchose ? 'item itemchose' : 'item'"
                :style="index === 7 ? 'margin:0' : ''"
                @click="choseItem(index)"
              >
                <img :src="item.imgurl" alt="" />
              </div>
            </div>
          </div>
          <div
            class="carouselbtn"
            style="padding-left: 10px"
            @click="carouselRight"
          >
            》
          </div>
        </div>
      </div>

      <h1>全部案例</h1>
      <p class="grayp">8万+头部达人选择、高效连接3亿用户</p>

      <div class="cardbox">
        <div
          v-for="(item, index) in allcards"
          :key="index"
          style="margin-bottom: 30px"
        >
          <personcasecard
            :personurl="item.imgurl"
            :name="item.name"
          ></personcasecard>
        </div>
      </div>

      <!-- <div class="imgshowtop2">
        <img
          src="https://oss.001ke.com/website/wellperson/2.png"
          alt="footerimg"
        />
      </div> -->
    </div>
    <footerer></footerer>
  </div>
</template>

<script>
import personcasecard from "./personcasecard.vue";
import headerer from "../headerer.vue";
import footerer from "../footerer.vue";
import { allcards, topdemo } from "@/const/wellperson";
export default {
  components: { headerer, footerer, personcasecard },
  data() {
    return {
      allcards,
      topdemo,
      topchose: 0,
      topinfo: {},
    };
  },
  created() {
    this.topinfo = this.topdemo[0];
  },
  methods: {
    choseItem(i) {
      this.topchose = i;
      this.topinfo = this.topdemo[i];
    },
    carouselLeft() {
      // const dom = document.querySelector('.carouselmain')
      const dom = document.querySelector(".carouselmain");
      if (this.topchose === 0) {
        dom.scrollTo(96 * 4, 0);
        this.topchose = 7;
        this.topinfo = this.topdemo[7];
        return;
      }
      dom.scrollBy(-96, 0);
      this.topchose -= 1;
      this.topinfo = this.topdemo[this.topchose];
    },
    carouselRight() {
      const dom = document.querySelector(".carouselmain");
      if (this.topchose === 7) {
        dom.scrollTo(0, 0);
        this.topchose = 0;
        this.topinfo = this.topdemo[0];
        return;
      }
      dom.scrollBy(96, 0);
      this.topchose += 1;
      this.topinfo = this.topdemo[this.topchose];
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 3px;
  margin-top: 20px;
}

h2 {
  margin: 0;
  margin-bottom: 12px;
}

p {
  margin: 0;
  font-size: 11px;
}

.imgshowtop {
  width: 100vw;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-image: linear-gradient(90deg, #ff772b 70%, #ff7f32 100%);
}

.imgshowtop2 {
  width: 100vw;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-image: linear-gradient(90deg, #fbe8eb 70%, #fdf3f5 100%);
}

/* stylelint-disable */
.imgshowtop img {
  width: 100vw;
  image-rendering: crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

/* stylelint-enable */

.div {
  background: linear-gradient(80.15deg, #eff4ff, #dde6ff);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.box {
  width: 100vw;
  box-sizing: border-box;
  padding: 10px;
}

.top {
  width: 100%;
  padding: 20px;
  display: flex;
}

.top .left {
  width: 108px;
  height: 145px;
  margin-right: 20px;
}

.top .left img {
  width: 108px;
  height: 145px;
  object-fit: cover;
  border-radius: 6px;
}

.top .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carousel {
  display: flex;
}

.carousel .carouselmain {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  position: relative;
  margin: 0 10px;
}

.carousel .carouselbtn {
  background: #fff;
  width: 28px;
  height: 86px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.carousel .carouselmain .item {
  width: 86px;
  height: 86px;
  border-radius: 4px;
  opacity: 0.5;
  flex-shrink: 0;
  cursor: pointer;
  margin-right: 10px;
}

.carousel .carouselmain .item img {
  width: 86px;
  height: 86px;
  object-fit: contain;
  border-radius: 4px;
}

.carousel .carouselmain .itemchose {
  opacity: 1;
}

.cardbox {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
}
</style>
