import { render, staticRenderFns } from "./personcasecard.vue?vue&type=template&id=5ca0dbaa&scoped=true&"
import script from "./personcasecard.vue?vue&type=script&lang=js&"
export * from "./personcasecard.vue?vue&type=script&lang=js&"
import style0 from "./personcasecard.vue?vue&type=style&index=0&id=5ca0dbaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca0dbaa",
  null
  
)

export default component.exports