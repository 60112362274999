export const allcards = [
  {
    imgurl: 'https://oss.oxke.net/1dd4f8346cbf2b23ae56dbf504945c10.jpg',
    name: '杨帆老师',
  },
  {
    imgurl: 'https://oss.oxke.net/933b9e93216ae2235c8c65466b0975f1.jpg',
    name: '向东老师',
  },
  {
    imgurl: 'https://oss.oxke.net/b358d8dbc2897d9a1657b43413d7961e.jpg',
    name: '何老师讲英语',
  },
  {
    imgurl: 'https://oss.oxke.net/84e9242757678bb63f669cdce00817b0.jpg',
    name: '星移考研',
  },
  {
    imgurl: 'https://oss.oxke.net/f4a70d892f7c92d548bb8d83e24b80df.jpg',
    name: '金欣摄影',
  },
  {
    imgurl: 'https://oss.oxke.net/97d0704e675bfc83bf97f006e04faed7.jpg',
    name: '林末未央',
  },
  {
    imgurl: 'https://oss.oxke.net/01929ee477463185012b81e835665e04.jpg',
    name: '满月老师',
  },
  {
    imgurl: 'https://oss.oxke.net/b29096c0e4f572b7820490e298b57088.jpg',
    name: '梅子老师说公考',
  },
  {
    imgurl: 'https://oss.oxke.net/51ffc86b66f0554519947076a4f68a4c.jpg',
    name: '秦天老师',
  },
  {
    imgurl: 'https://oss.oxke.net/85617c341e3aeeb2541d3b50f9e11cf8.jpg',
    name: '孙嘉树',
  },
  {
    imgurl: 'https://oss.oxke.net/17f6b8d7f565e3966be1c33f1b172ea4.jpg',
    name: '南梦心理导师',
  },
  {
    imgurl: 'https://oss.oxke.net/a4bc2f4eb68918881137ada67b77d707.jpg',
    name: '知夏讲职场',
  },
  {
    imgurl: 'https://oss.oxke.net/af1ab834d74a216f4c9155df33058fb2.jpg',
    name: '小路老师',
  },
  {
    imgurl: 'https://oss.oxke.net/348660ded2f597ef1bc467aad6e8d688.jpg',
    name: '秦月历史',
  },
]

export const topdemo = [
  {
    name: 'Lucky老师',
    des: [
      '🏅️8年丰富行业培训经验，培训人次上万次',
      '📖授课内容：行测、面试、报考咨询顾问',
      '💪一针见血指出问题，帮助学员解决痛点',
      '⏰每天抖音直播开课，梳理重难点，助你“一举成公”',
    ],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/33.jpeg',
  },
  {
    name: '陈博讲公考',
    des: [
      '8年线下行业培训经验，学员上岸率95%',
      '8年公考、事业编考试培训经验倾囊相授',
      '专注公考、分享干货、轻松上岸、好找工作',
      '授课内容：行测、申论、真题解析、报考咨询',
    ],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/1.jpeg',
  },
  {
    name: '太阳有氧运动健身',
    des: [
      '全g建身操团体赛🏆',
      'BigMan 体能训炼师',
      '国j建身z格认正',
      '脊柱孕产师认正',
      '搏击国j认正',
    ],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/4.jpeg',
  },
  {
    name: '随手做美食',
    des: ['专注家常菜，天天都是家里的味道', '知名美食博主'],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/5.jpeg',
  },
  {
    name: '設计」青年',
    des: ['情理之中，意料之外，就是设计最好的定位', '室内设计师'],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/6.jpeg',
  },
  {
    name: '中考高分数学',
    des: [
      '央视专题纪录片',
      'BEIJING REVIEW 专访',
      'China news 专访',
      '百度优课教师培训首席讲师',
    ],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/7.jpeg',
  },
  {
    name: '星小目围棋',
    des: ['大赛直播，手术刀级别的讲解', '目力之外，思想奔赴浩瀚的星空'],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/8.jpeg',
  },
  {
    name: '小白学驾照 ',
    des: [
      '跟我学科目一，让你成为老司机',
      '每天直播科目一，科目四知识',
      '专业的课程资料，学驾照更简单',
    ],
    imgurl: 'https://oss.001ke.com/website/wellperson/topdemo/9.jpeg',
  },
]
