<template>
  <div class="personcard">
    <img :src="personurl" alt="" />
    <h3>{{ name }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    personurl: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>

h3{
  font-size: 14px;
  margin-top: 10px;
}
.personcard {
  width: 108px;
  height: 143px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0.04rem 0.24rem 0 rgb(157 180 226);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.personcard img {
  width: 108px;
  height: 108px;
  object-fit: contain;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


</style>
